import React, { useState, useRef } from "react";
// import ReactPlayer from "react-player/lazy";
import posterBg from "../../img/poster-bg.png";
// import { useNavigate } from "react-router-dom";
import { clsx } from "../../utils/mergeClassNames";


const Floor2AIDocent = ({ docentVideoSrc, description }) => {

    const videoRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const playVideo = () => {
        videoRef.current.play();
        if (!isVideoPlaying) setIsVideoPlaying(true);
    }

    return (
        // bg-[#718bfd]
        <div className="fixed bottom-0 w-full h-[40%] z-50 bg-white">
            {/* <div className="relative bg-blue-300 h-full w-1/3 float-left bg-transparent">
                hello
            </div> */}
            <div className="z-50 p-4 fixed flex-col bottom-0 left-0 w-[55%] h-[40%] bg-white flex justify-start">
                <div className="text-sm mb-[0rem] font-medium tracking-tight">{description?.name}</div>
                <div className="leading-7 text-cyan-700 text-3xl mb-4 font-medium tracking-tight">{description?.englishName}</div>
                {/* <div className="mb-2 text-xs font-semibold">
                    <span className="text-cyan-700 font-bold text-[0.65rem]">쥐라기 중/후기에</span> 서식
                </div> */}
                <div className="mb-2 flex items-center">
                    <span className="rounded-sm bg-cyan-900 text-white px-[0.4rem] py-[0.2rem] mr-[0.4rem] text-[0.5rem]">분류</span>
                    <span className="text-[0.55rem] font-bold tracking-tighter">{description?.category}</span>
                </div>
                <div className="mb-2 flex items-center">
                    <span className="rounded-sm bg-cyan-900 text-white px-[0.4rem] py-[0.2rem] mr-[0.4rem] text-[0.5rem]">크기</span>
                    <span className="text-[0.55rem] font-bold tracking-tighter">{description?.size}</span>
                </div>
                {/* <div className="mb-2 flex items-center">
                    <span className="rounded-sm bg-cyan-900 text-white px-[0.4rem] py-[0.2rem] mr-[0.4rem] text-[0.5rem]">수명</span>
                    <span className="text-[0.55rem] font-bold tracking-tighter">{description.lifeSpan}</span>
                </div> */}
                <div className="text-[0.65rem] leading-normal font-light tracking-tight overflow-scroll">
                    <span className="font-semibold">
                        {description.content}
                    </span>
                </div>
            </div>
            <div className="p-4 fixed bottom-0 w-[45%] h-[40%] flex items-center justify-center bg-white -right-1">
                {/* <ReactPlayer
                    className="relative w-full h-full object-cover"
                    url={docentVideoSrc}
                    width="100%" 
                    height="100%"
                    playIcon={<PlayButton />}
                    playing={true} 
                    playsinline={true}
                    controls={false}
                    light={posterBg}
                /> */}
                <video 
                    ref={videoRef}
                    src={docentVideoSrc}
                    className="relative w-full h-full object-cover"
                    // controls
                    playsInline
                    poster={posterBg}
                    onEnded={() => setIsVideoPlaying(false)}
                />
            </div>
            <div className="p-4 fixed bottom-0 right-0 w-[45%] h-[40%] flex items-center justify-center">
                <button
                    onClick={playVideo}
                    className={clsx("text-white text-2xl bg-cyan-800 bg-opacity-40 py-3 px-4 rounded-md", 
                    isVideoPlaying ? "hidden" : "", 
                    )}
                >
                    PLAY
                </button>
            </div>
        </div>
    )
};

export default Floor2AIDocent;