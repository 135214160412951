import { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Resize from "../../libs/Resize";
import loadAsyncGLTF from "../../libs/loadAsyncGLTF";
// import skyBg from "../../img/universe-bg.jpg";
import px from "../../img/Cubemap/floor1/px.jpeg";
import py from "../../img/Cubemap/floor1/py.jpeg";
import pz from "../../img/Cubemap/floor1/pz.jpeg";
import nx from "../../img/Cubemap/floor1/nx.jpeg";
import ny from "../../img/Cubemap/floor1/ny.jpg";
import nz from "../../img/Cubemap/floor1/nz.jpeg";
// let width = window.innerWidth;
// let height = window.innerHeight;

const Floor1DetailScene = ({ canvasStyle, model }) => {

  const containerRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {

    const createScene = () => {

      const mixers = [];
      const container = containerRef.current;
      const canvas = canvasRef.current;


      /* <createScene> FUNCTION #1. renderScene() */
      const renderScene = async () => {

        const { width, height } = container.getBoundingClientRect();

        /* 1. Setup scene */
        const scene = new THREE.Scene();
        
        /* 2. Setup camera */
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 10000);
        camera.position.set(0, 0, -100);

        /* 3. Setup renderer */
        const renderer = new THREE.WebGLRenderer({
          canvas,
          antialias: true,
        });

        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;

        ///////////////////////////////////////////////////////////////
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 0.7;
        scene.environment = THREE.sRGBEncoding;
        ///////////////////////////////////////////////////////////////


        /* 4. Add 3D Model(s) */

        ///////////////////////////////////////////////////////////////
        /* 
          Load GLB Model(s) && <cubemapTexture> 
        */

        const cubemapLoader =  new THREE.CubeTextureLoader();

        // eslint-disable-next-line
        const [gltf, cubemapTexture] =  await Promise.all([
          loadAsyncGLTF({ 
            scene, 
            mixers, 
            modelSrc: model.modelSrc,
            animationName: model.animationName,
            position: model.position,
            scale: model.scale,
            rotation: model.rotation,
          }),
          cubemapLoader.loadAsync([px, nx, py, ny, pz, nz]),
        ]);

        scene.background = cubemapTexture;
        ///////////////////////////////////////////////////////////////


        /* Add AmbientLight */
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        /* Add PointLight */
        const pointLightColor = 0xffffff;
        const pointLightIntensity = 1;
        const pointLight = new THREE.PointLight(pointLightColor, pointLightIntensity);
        pointLight.castShadow = true;
        pointLight.shadow.mapSize.width = 2048;
        pointLight.shadow.mapSize.height = 2048;
        pointLight.shadow.radius = 5;
        pointLight.position.set(40, 120, 40);
        scene.add(pointLight);


        /* 5. Setup orbital controls */
        const controls = new OrbitControls(camera, canvas);
        controls.enableKeys = false;
        controls.enablePan = false;
        controls.enableZoom = true;
        controls.enableDamping = true;
        controls.enableRotate = true;
        // controls.dampingFactor = 0.007; // friction (rotation speed)
        controls.rotateSpeed = 1; // mouse sensitivity
        controls.autoRotate = true;
        controls.minPolarAngle = 0 * (Math.PI / 180);
        controls.maxPolarAngle = 70 * (Math.PI / 180); 
        controls.minDistance = 50;
        controls.maxDistance = 200;


        /* 6. run animate() -> <requestAnimationFrame> */
        const clock = new THREE.Clock();

        const animate = () => {
          controls.update(); // orbitControls.autoRotate is enabled so orbitControls.update must be called inside animation loop.
          requestAnimationFrame(animate);
          
          const delta = clock.getDelta();
          const elapsedTime = clock.getElapsedTime();

          mixers?.forEach((mixer) => {
            mixer.update(delta);
          })

          model.rotation.y = elapsedTime * 0.3;

          camera.lookAt(scene.position);
          camera.updateProjectionMatrix();

          renderer.render(scene, camera);

        };


        animate();

        /* Adjust resized Screen width & height */
        const resize = new Resize(camera, renderer);
        resize.start();

      };

      return navigator.onLine
        ? renderScene() 
        : console.error("Please make sure that you are connected to the Internet!");

    };

    /* FINALLY!! - run createScene() */
    createScene();

  }, [model]);

  return (
    <div 
      ref={containerRef}
      className="relative w-full h-full"
    >
      <canvas
        ref={canvasRef}
        id="canvas"
        width="100%"
        height="100%"
        style={{ ...canvasStyle }}
      />
    </div>
  ); 
};

export default Floor1DetailScene;
