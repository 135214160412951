const VMFloor2ObjectsList = {
    "1": {
        title: "#1: MAMMOTH",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-mammoth.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Mammoth.glb",
            modelBg: "cenozoic",
            animationName: "mammoth-walk",
            position: [0, 5, 15], 
            scale: [11, 11, 11], 
            rotation: [0, 160, 0],
        },
    },
    "2": {
        title: "#2: SMILODON",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-similodon.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Smilodon.glb",
            modelBg: "cenozoic",
            animationName: "Armature|smilodon-idle",
            position: [0, 10, 0], 
            scale: [3.5, 3.5, 3.5], 
            rotation: [0, 100, 0],
        },
    },
    "3": {
        title: "#3: SPINOSAURUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-spinosaurus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Spinosaurus.glb",
            modelBg: "mesozoic",
            animationName: "spinosaurus-attack",
            position: [0, 10, 0], 
            scale: [7, 7, 7], 
            rotation: [0, 160, 0],
        },
    },
    "4": {
        title: "#4: STEGOSAURUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-stegosaurus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Stegosaurus.glb",
            modelBg: "mesozoic",
            animationName: "Stegosaurus|Stegosaurus|stegosaurus-idle",
            position: [0, 10, 0], 
            scale: [8.5, 8.5, 8.5], 
            rotation: [0, 10, 0],
        },
    },
    "5": {
        title: "#5: T-REX",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-trex.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Trex.glb",
            modelBg: "mesozoic",
            animationName: "idle investigate loop",
            position: [0, 10, 0], 
            scale: [10, 10, 10], 
            rotation: [0, 10, 0],
        },
    },
    "6": {
        title: "#6: TRICERATOPS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-triceratops.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Triceratops.glb",
            modelBg: "mesozoic",
            animationName: "triceratops-attack",
            position: [0, 10, 0], 
            scale: [10, 10, 10], 
            rotation: [0, 160, 0],
        },
    },
    "7": {
        title: "#7: ANOMALOCARIS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-anomalocaris.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Anomalocaris.glb",
            modelBg: "paleozoic",
            animationName: "AnomalocarisArmature|AnomalocarisArmature|AnomalocarisArmature|",
            position: [0, 30, 0], 
            scale: [8, 8, 8], 
            rotation: [0, 10, 0],
        },
    },
    "8": {
        title: "#8: DUNKLEOSTEUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-dunkleosteus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Dunkleosteus.glb",
            modelBg: "paleozoic",
            animationName: "Bite",
            position: [0, 30, 0], 
            scale: [9, 9, 9], 
            rotation: [0, 10, 0],
        },
    },
    "9": {
        title: "#9: HELICOPRION",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-helicoprion.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Helicoprion.glb",
            modelBg: "paleozoic",
            animationName: "H_Roar",
            position: [0, 20, 0], 
            scale: [7, 7, 7], 
            rotation: [0, 9.7, 0],
        },
    },
    "10": {
        title: "#10: THELODUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor2/floor2-ai-docent-thelodus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Thelodus.glb",
            modelBg: "paleozoic",
            animationName: "Swimming",
            position: [0, 30, 0], 
            scale: [10, 10, 10], 
            rotation: [0, 10, 0],
        },
    },
};

export default VMFloor2ObjectsList;