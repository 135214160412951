// import { useNavigate } from "react-router-dom";
import arrowLeft from "../../img/arrow-left.png";
import arrowRight from "../../img/arrow-right.png";
import arrowLeftWhite from "../../img/arrow-left-white.png";
import arrowRightWhite from "../../img/arrow-right-white.png";
import { clsx } from "../../utils/mergeClassNames";

const DetailArrows = ({ floor, currentPageNumber, lastPageNumber }) => {

    // const navigate = useNavigate();

    return (
        <div className="fixed bottom-[40%] w-full h-12 z-50 bg-transparent">
            <div className="flex justify-between"> 
            <img 
                // className="bg-transparent"
                className={clsx("bg-transparent",
                    currentPageNumber === 1 ? "invisible": ""
                )}
                alt="arrow-left"  
                src={floor === "floor3" ? arrowLeftWhite : arrowLeft} 
                width={floor === "floor3" ? "25" : "30"} 
                height={floor === "floor3" ? "25" : "30"} 
                onClick={() => { 
                    if (currentPageNumber > 1) {
                        const previousPageNumber = currentPageNumber - 1;
                        const previousPage = `/virtual-museum/${floor}/${previousPageNumber}`; 
                        // navigate(previousPage); 
                        window.location.href = previousPage;
                    }
                    else {
                        return;
                    }
                }}
            />
            <img 
                // className="bg-transparent"
                className={clsx("bg-transparent",
                currentPageNumber >= lastPageNumber ? "invisible": ""
            )}
                alt="arrow-right" 
                src={floor === "floor3" ? arrowRightWhite : arrowRight} 
                width={floor === "floor3" ? "25" : "30"} 
                height={floor === "floor3" ? "25" : "30"} 
                onClick={() => { 
                    if (currentPageNumber < lastPageNumber) {
                        const nextPageNumber = currentPageNumber + 1;
                        const nextPage = `/virtual-museum/${floor}/${nextPageNumber}`; 
                        // navigate(nextPage); 
                        window.location.href = nextPage;
                    }
                    else {
                        return;
                    }
                }}
            />
            </div>
        </div>
    )
};

export default DetailArrows;