import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";


/* UTILITIES */
import StorageContext from "./utils/contexts/StorageContext";
import { useLocalStorage, useSessionStorage } from "./utils/useStorage";


/* PC PAGES */
import PCMain from "./pages/PCMain";
import EmailSignup from "./pages/EmailSignup";
import VerifyEmail from "./pages/VerifyEmail";
import Login from "./pages/Login";
import RequestResetPassword from "./pages/RequestResetPassword";
import ResetPassword from "./pages/ResetPassword";

import UnityDownloadV1 from "./pages/UnityDownloadV1";
import UnityDownloadV2 from "./pages/UnityDownloadV2";


/* MOBILE PAGES */
// import MobileMain from "./pages/MobileMain";
import MobileMain from "./pages/MobileMain";
import VirtualMuseumMain from "./pages/VMMain";
import VMFloor1Detail from "./pages/VMFloor1Detail";
import VMFloor2Detail from "./pages/VMFloor2Detail";
import VMFloor3Detail from "./pages/VMFloor3Detail";
import ExperienceHallMain from "./pages/ExperienceHallMain";


/* COMPONENTS */
// import VisitorLog from "./components/VisitorLog"; 

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: true,
      enabled: true,
    },
  },
});

const App = () => {

  const [sessionLoggedInAt, setSessionLoggedInAt] = useSessionStorage("loggedInAt", "");
  const [localLanguage, setLocalLanguage] = useLocalStorage("language", "korean");
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [registeredAccountId, setRegisteredAccountId] = useState("");
  const [isUnityDownloaded, setIsUnityDownloaded] = useState(false);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <StorageContext.Provider value={{  

          localLanguage, setLocalLanguage, 
          sessionLoggedInAt, setSessionLoggedInAt,

          isLoggedIn, setIsLoggedIn,
          registeredAccountId, setRegisteredAccountId,
          isUnityDownloaded, setIsUnityDownloaded,

      }}>
          <BrowserRouter>
          {/* <VisitorLog /> */}
            <Routes>
              <Route path="/" element={<PCMain />} />
              <Route path="/email-signup" element={<EmailSignup />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/request-reset-password" element={<RequestResetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/download" element={<UnityDownloadV1 />} />
              <Route path="/download-zip" element={<UnityDownloadV2 />} />

              <Route path="/mobile-main" element={<MobileMain />} />
              <Route path="virtual-museum" element={<VirtualMuseumMain />} />
              <Route path="virtual-museum/floor1/:id" element={<VMFloor1Detail />} />
              <Route path="virtual-museum/floor2/:id" element={<VMFloor2Detail />} />
              <Route path="virtual-museum/floor3/:id" element={<VMFloor3Detail />} />

              <Route path="experience" element={<ExperienceHallMain />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes> 
          </BrowserRouter>
        </StorageContext.Provider> 
      </QueryClientProvider>
    </div>
  );
};

export default App;