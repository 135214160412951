import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VMFloor1ObjectsList from "./VMFloor1ObjectsList";
import Floor1DetailScene from "../../components/Floor1DetailScene"
import Floor1AIDocent from "../../components/Floor1AIDocent";
import Floor1DescriptionsList from "./VMFloor1DescriptionsList";
import TopNavBar from "../../components/TopNavBar";
import { isMobile } from "react-device-detect";
import DetailArrows from "../../components/DetailArrows";

const lastPageNumber = 3; // <Floor #1>의 총 전시물 개수

const VMFloor1Detail = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            navigate("/"); 
        };
    }, [navigate]);

    useEffect(() => {
        if (!(parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) { // 전시물 개수보다 1이상 <lastPageNumber>이하가 "아닌" 경우
            navigate("/"); 
        };
    }, [navigate, id]); 

    if (!(isMobile && parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) return;

    return ( 
        <div className="w-screen h-screen">
            <TopNavBar />
            {/* <div className="fixed w-full text-center text-white text-4xl py-7 px-5">
                {VMFloor1ObjectsList[id]?.title}
            </div> */}

            <DetailArrows 
                floor="floor1" 
                currentPageNumber={parseInt(id)}
                lastPageNumber={lastPageNumber} 
            />
            
            <Floor1DetailScene 
                model={VMFloor1ObjectsList[id]?.model}
                canvasStyle={{ outline: "none" }}
            />

            <Floor1AIDocent 
                docentVideoSrc={VMFloor1ObjectsList[id]?.docentVideoSrc} 
                description={Floor1DescriptionsList[id]?.korean}
            />
        </div>
    )
};

export default VMFloor1Detail;

// const VMFloor1Detail = () => {

//     const { id } = useParams();
 
//     return (
//         <div className="w-screen h-screen">
//             <div className="fixed w-full text-center text-white text-4xl py-7 px-5">
//                 {VMFloor1ObjectsList[id]?.title}
//             </div>
            
//             <Floor1Detail 
//                 model={VMFloor1ObjectsList[id]?.model}
//                 canvasStyle={{ outline: "none" }}
//             />

//             <AIDocentVideo docentVideoSrc={VMFloor1ObjectsList[id]?.docentVideoSrc} />
//         </div>
//     )
// };

// export default VMFloor1Detail;