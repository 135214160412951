const VMFloor3Descriptions = {
    "1": {
        korean: {
            name: "태양",
            englishName: "Sun",
            diameter: "약 1,390,000 KM",
            mass: "지구의 33만배",
            // distance: "지구로부터 약 1억 5000만 KM (1 AU)",
            content: "태양의 질량은 태양계 총 질량의 99%이상을 차지하며, 태양계에 존재하는 유일한 항성 (별)이자 모든 에너지의 근원입니다. 태양은 지구로부터 약 1억 5,000만 KM 떨어져있습니다. 태양의 표면 온도는 5,800K이지만, 중심부의 온도는 1,500만K에 달합니다. 평균 밀도는 1.41g/㎤ 으로 비교적 낮은 값을 보이는데, 이는 태양이 가벼운 수소(73.46%)나 헬륨(24.85%)으로 이루어져 있음을 뜻합니다. 태양 복사에너지의 원천은 총 질량의 73%를 차지하고 있는 수소의 핵융합 반응에 있습니다.",
        },
    },
    "2": {
        korean: {
            name: "수성",
            englishName: "Mercury",
            diameter: "약 4,880 KM",
            mass: "지구의 0.055 배",
            // distance: "태양으로부터 약 5,791만 KM",
            content: "태양으로부터 가장 가까운 행성인 수성은 크기가 작고 중력이 작아 행성 주위에 기체분자를 붙잡아둘 수 없기 때문에 대기가 없습니다. 태양으로부터 5,791만 KM 떨어져 있으며 87.98일의 공전주기와 58.65일의 자전주기를 가졌습니다. 태양에서 가깝고, 자전주기가 길어 낮 시간이 길기 때문에 낮과 밤의 온도차가 매우 큽니다. 수성의 표면은 무수한 크레이터로 덮여있습니다. 이들은 생성 초기에 무수히 떨어진 운석과의 충돌로 생긴 것 입니다.",
        },
    },
    "3": {
        korean: {
            name: "금성",
            englishName: "Venus",
            diameter: "약 12,100 KM",
            mass: "지구의 0.815 배",
            // distance: "태양으로부터 약 1억 800만 KM",
            content: "금성은 그 크기와 질량이 지구와 가장 비슷하며 태양으로부터 약 1억 800만 KM 떨어져 있습니다. 225일의 공전주기와 -243일의 자전주기를 가졌습니다. 자전주기가 -243일인 이유는 거꾸로 자전하기 때문이며 이 때문에 금성에서는 해가 서쪽에서 뜹니다. 금성의 표면은 놀랍게도 460℃나 되는데, 이는 대기 중 이산화탄소의 극심한 온실효과 때문입니다. 이로 인해 금성은 태양에 더 가까운 수성보다도 더 뜨거운 행성이 되었습니다.",
        },
    },
    "4": {
        korean: {
            name: "지구",
            englishName: "Earth",
            diameter: "약 12,700 KM",
            mass: "지구의 1배",
            // distance: "태양으로부터 약 1억 5000만 KM (1 AU)",
            content: "우주에서 보았을 때 지구는 넓은 바다, 산소가 풍부한 얇은 대기층, 숲으로 덮인 육지 등으로 인해 푸른색으로 밝게 빛나는 아름다운 행성입니다. 태양으로부터 1억 5,000만 KM 떨어져 있습니다. 지구가 생명이 살기에 적당한 푸른 행성이 될 수 있었던 것은, 대기권을 만들기에 충분한 중력을 갖고 있으며, 태양으로부터 적당한 거리에 떨어져 있어 금성처럼 너무 뜨겁지도 화성처럼 너무 춥지도 않아 풍부한 물을 가질 수 있었기 때문입니다.",
        },
    },
    "5": {
        korean: {
            name: "화성",
            englishName: "Mars",
            diameter: "약 6,780 KM",
            mass: "지구의 0.107배",
            // distance: "태양으로부터 약 2억 2,800만 KM",
            content: "화성의 대기는 공기가 희박하며 그 주성분은 96%가 이산화탄소이고 수증기와 산소가 약간 있습니다. 태양으로부터 2억 2,800만 KM 떨어져 있으며 687일의 공전주기와 1.03일의 자전주기를 가지고 있습니다. 화성의 평균 온도는 -63℃이며, 최고온도는 35℃, 최저온도는 -140℃입니다. 화성의 표면은 산화철로 이루어진 먼지로 덮여 있어 붉은 색을 띱니다. 화성의 표면에는 물이 흘렀던 흔적으로 보이는 마모된 역암, 골짜기, 말라버린 강바닥, 거대한 협곡 등이 있는데, 이는 과거에 물이 있었음을 암시합니다.",
        },
    },
    "6": {
        korean: {
            name: "목성",
            englishName: "Jupiter",
            diameter: "약 140,000 KM",
            mass: "지구의 317.8배",
            // distance: "태양으로부터 약 7억 7900만 KM",
            content: "목성은 태양계에서 가장 큰 행성이며 태양으로부터 약 7억 7,900만 KM 떨어져 있습니다. 11.86년의 공전주기와 9.93시간의 자전주기를 가졌습니다. 목성은 토성처럼 고리를 가지고 있지만 너무 희미하여 지구에서는 보이지 않습니다. 대기는 주로 수소(90%)와 헬륨(10%)으로 이루어져 있습니다. 평균 표면 온도는 -108℃이며 목성의 사진에서 보이는 줄무늬는 빠른 자전으로 인해 생기는 대기 현상입니다. 목성에서는 대적점(Great Red Spot)이라고 부르는 지구보다 더 큰 붉은 점이 보이는데 이것은 거대한 대기의 소용돌이입니다.",
        },
    },
    "7": {
        korean: {
            name: "토성",
            englishName: "Saturn",
            diameter: "약 116,000 KM",
            mass: "지구의 95.2배",
            // distance: "태양으로부터 약 14억 3000만 KM",
            content: "토성은 태양계에서 두 번째로 큰 행성이며 태양으로부터 약 14억 3,000만 KM 떨어져 있습니다. 29.5년의 공전주기와 10.5시간의 자전주기를 가지고 있습니다. 대기는 주로 수소(96%)로 이루어져 있으며, 소량의 헬륨과 메탄을 포함하고 있습니다. 평균 표면온도는 -139℃이며 물보다도 낮은 밀도를 가지고 있어 토성을 넣을 수 있는 바다가 있다면 그 바다 위에서 토성은 둥둥 떠다닐 것입니다. 토성은 적도면을 따라 옅은 노란색으로 빛나는 고리로 둘러싸여 있는데, 이 고리를 구성하는 물질은 대부분이 수 cm에서 수 m 크기의 얼음덩어리나 돌덩어리들입니다.",
        },
    },
    "8": {
        korean: {
            name: "천왕성",
            englishName: "Uranus",
            diameter: "약 50,700 KM",
            mass: "지구의 14.5배",
            // distance: "태양으로부터 약 28억 8000만 KM",
            content: "천왕성은 태양계에서 3번째로 큰 행성이며 태양으로부터 약 28억 8,000만 KM 떨어져 있습니다. 84년의 공전주기와 17.2시간의 자전주기를 가졌습니다. 대기는 주로 수소(83%)와 헬륨(15%)으로 구성되어 있으며, 소량의 메탄(2%)을 포함하고 있습니다. 평균 표면 온도는 -197℃입니다. 천왕성의 자전축은 옆으로 기울어져 공전면와 거의 평행한데, 이러한 비정상적인 자전축의 기울기는 태양계 형성 초기에 행성 크기의 물체와 충돌한 결과라고 추정됩니다.",
        },
    },
    "9": {
        korean: {
            name: "해왕성",
            englishName: "Neptune",
            diameter: "약 49,200 KM",
            mass: "지구의 17.1배",
            // distance: "태양으로부터 약 45억 KM",
            content: "해왕성의 대기는 천왕성과 비슷하게 수소(80%)와 헬륨(19%)이 주를 이루고 소량의 메탄(1.5%)이 포함되어 있습니다. 태양으로부터 약 45억 KM 떨어져 있으며 165년의 공전주기와 16시간의 자전주기를 가졌습니다. 표면온도는 -201℃ 로 모든 물질이 고체 상태로 얼어붙어 있습니다. 해왕성에서는 매우 강한 바람, 시속 수백 km의 폭풍, 밝은 색의 구름 등이 관찰되어 대기가 활발하게 움직이고 있음을 알 수 있습니다. 해왕성은 좁고 희미한 5개의 고리를 가지고 있습니다.",
        },
    },
};

export default VMFloor3Descriptions;