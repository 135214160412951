const VMFloor1Descriptions = {
    "1": {
        korean: {
            name: "청둥오리",
            englishName: "Mallard",
            category: "기러기목 오리과의 조류",
            size: "50~65CM",
            // lifeSpan: "30년",
            content: "청둥오리는 대한민국의 흔한 겨울새이며 만, 호수, 못, 간척지, 하천 등에서 겨울을 납니다. 낮에는 만이나 호수 등 앞이 트인 곳에서 먹이를 찾고 저녁이 되면 논이나 습지로 이동합니다. 풀 씨나 나무열매 등 식물성 먹이 외에 곤충류 등 동물성 먹이도 먹는 잡식성 입니다. 수컷의 부리는 뚜렷한 노란색이며 머리는 녹색을 띠며, 흰색의 가는 목 테가 있습니다. 암컷은 갈색으로 얼룩집니다.",
        },
    },
    "2": {
        korean: {
            name: "여우",
            englishName: "Red Fox",
            category: "개과에 속한 포유류",
            size: "60~80CM",
            // lifeSpan: "6~10년 (최대 15년)",
            content: "여우는 한반도에서는 제주, 울릉도를 제외한 한반도 전역에 분포했으나 1980년 DMZ에서 발견된 이후 최근까지 발견되지 않다가 2004년 강원도 양구에서 사체가 확인된 바 있습니다. 현재 소백산에서 복원 사업을 진행하고 있습니다. 몸은 갈색에서 붉은 색을 띠며 꼬리가 길고 두꺼우며 털이 많습니다. 잡식성으로 설치류를 즐겨 먹으며 인가 주변의 야산에서 서식합니다. 적응력이 매우 뛰어나서 야생의 개과 동물 중에서 가장 개체 수가 많은 것으로 추정하고 있습니다.",
        },
    },
    "3": {
        korean: {
            name: "민들레",
            englishName: "Dandelion",
            category: "속씨식물문 쌍자엽강",
            size: "6~15CM",
            // lifeSpan: "6~10년 (최대 15년)",
            content: "민들레는 매우 흔하게 보이는 다년생초로서 들판에서 볕이 잘 드는 곳에서 자랍니다. 톱니 모양의 잎새와 눈에 확 띄는 노란 꽃이 인상적이며 꽃이 지고 나면 솜털 같은 씨앗들이 나옵니다. 이 씨앗들은 바람을 타고 날아가서 널리 퍼집니다. 토종 민들레는 충매화로 적절한 매개체가 없으면 씨를 맺기 어려운 데다 환경오염에도 취약합니다. 도시화가 이루어진 지역의 길가에 핀 민들레는 대부분이 귀화식물 민들레 입니다.",
        },
    },
};

export default VMFloor1Descriptions;