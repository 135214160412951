// import { useNavigate } from "react-router-dom";
import MobileMainScene from "../../components/MobileMainScene";

const mainModelsList = {
    "1": {
        title: "#1: AVATAR",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent-video-01-resized2.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/main/MainAvatar.glb",
            modelBg: "paleozoic",
            animationName: "Armature|mixamo.com",
            position: [-10, 10, -20], 
            scale: [50, 50, 50], 
            rotation: [0, -0.2, 0],
        },
    },
    "2": {
        title: "#2: AVATAR",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent-video-01-resized2.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/main/MainSpinosaurus.glb",
            modelBg: "mesozoic",
            animationName: "spinosaurus-roar",
            position: [18, 20, -200], 
            scale: [12.5, 12.5, 12.5], 
            rotation: [0, 2.7, 0],
        },
    },
    "3": {
        title: "#3: AVATAR",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent-video-01-resized2.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/main/MainHelicoprion.glb",
            modelBg: "paleozoic",
            animationName: "helicoprion-attack",
            position: [20, 40, -380], 
            scale: [13, 13, 13], 
            rotation: [0, -0.4, 0],
        },
    },
    "4": {
        title: "#4: AVATAR",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent-video-01-resized2.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor2/Mammoth.glb",
            modelBg: "cenozoic",
            animationName: "mammoth-walk",
            position: [0, 45, -50], // -650
            scale: [9.5, 9.5, 9.5], 
            rotation: [0, -0.4, 0],
        },
    },
};

const MobileMain = () => {
 
    return (
        <div className="w-screen h-screen">            
            <MobileMainScene 
                mainModelsList={mainModelsList} // {virtualMuseumObjectsList["1"]?.model}
                canvasStyle={{ outline: "none" }}
            />
        </div>
    )
};

// const MobileMain = () => {

//     const navigate = useNavigate();

//     const movePage = () => {
//         navigate("/virtual-museum/21");
//     };

//     return (
//         <div className="w-screen h-screen">
//             <MainSceneComp 
//                 canvasStyle={{ outline: "none" }}
//             />
//             <div className="fixed bottom-0 w-full h-1/4 flex items-center justify-center bg-gray-400 z-50">
//                 <button onClick={movePage} className="text-white">MOVE TO DETAIL PAGE</button>
//             </div>
//         </div>
//     )
// }; 

export default MobileMain;