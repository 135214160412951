import { useContext } from "react";
import { clsx } from "../../utils/mergeClassNames";
import { useNavigate } from "react-router-dom";
import ModalContext from "../../utils/contexts/ModalContext";
import StorageContext from "../../utils/contexts/StorageContext";

export const SignupSuccessModal = ({ message, moveToPage }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 
    const navigate = useNavigate();

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isSignupModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "성공" : "Success"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => {
                            modalContext.setIsSignupModalRequired(false);
                            navigate(moveToPage);
                        }}
                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const SignupFailModal = ({ message }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isSignupModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "실패" : "Fail"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => modalContext.setIsSignupModalRequired(false)}
                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const LoginSuccessModal = ({ message, moveToPage }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 
    const navigate = useNavigate();

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isLoginModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "성공" : "Success"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => {
                            modalContext.setIsLoginModalRequired(false);
                            navigate(moveToPage);
                        }}
                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const LoginFailModal = ({ message }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isLoginModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "실패" : "Fail"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => modalContext.setIsLoginModalRequired(false)}
                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};






export const RequestResetPasswordSuccessModal = ({ message, moveToPage }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 
    const navigate = useNavigate();

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isRequestResetPasswordModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "성공" : "Success"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => {
                            modalContext.setIsRequestResetPasswordModalRequired(false);
                            navigate(moveToPage);
                        }}
                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const RequestResetPasswordFailModal = ({ message }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isRequestResetPasswordModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "실패" : "Fail"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => modalContext.setIsRequestResetPasswordModalRequired(false)}
                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};






export const ResetPasswordSuccessModal = ({ message, moveToPage }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 
    const navigate = useNavigate();

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isResetPasswordModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "성공" : "Success"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => {
                            modalContext.setIsResetPasswordModalRequired(false);
                            navigate(moveToPage);
                        }}
                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const ResetPasswordFailModal = ({ message }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isResetPasswordModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "실패" : "Fail"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => modalContext.setIsResetPasswordModalRequired(false)}
                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};






export const VerifyEmailSuccessModal = ({ message, moveToPage }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 
    const navigate = useNavigate();

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isVerifyEmailModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "성공" : "Success"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => {
                            modalContext.setIsVerifyEmailModalRequired(false);
                            navigate(moveToPage);
                        }}
                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};

export const VerifyEmailFailModal = ({ message }) => {

    const storageContext = useContext(StorageContext); 
    const modalContext = useContext(ModalContext); 

    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full z-50",
            modalContext.isVerifyEmailModalRequired ? "" : "hidden"
        )}>
            <div className="mt-3 text-center">
                <div
                    className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-500"
                >
                    <svg
                        className="h-8 w-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <h3 className="mt-5 text-xl leading-6 font-medium text-white">
                    {storageContext.localLanguage !== "english" ? "실패" : "Fail"}
                </h3>
                <div className="mt-2 px-7 py-3">
                    <p className="text-md text-white">
                        {message}
                    </p>
                </div>
                <div className="items-center px-4 py-3">
                    <button
                        onClick={() => modalContext.setIsVerifyEmailModalRequired(false)}
                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        {storageContext.localLanguage !== "english" ? "확인" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    )
};