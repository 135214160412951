import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VMFloor2ObjectsList from "./VMFloor2ObjectsList";
import Floor2DetailScene from "../../components/Floor2DetailScene";
import Floor2AIDocent from "../../components/Floor2AIDocent";
import Floor2DescriptionsList from "./VMFloor2DescriptionsList";
import TopNavBar from "../../components/TopNavBar";
import { isMobile } from "react-device-detect"; 
import DetailArrows from "../../components/DetailArrows";

const lastPageNumber = 10; // <Floor #2>의 총 전시물 개수

const VMFloor2Detail = () => {

    const { id } = useParams(); 
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        if (!(parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) { // 전시물 개수보다 1이상 <lastPageNumber>이하가 "아닌" 경우
            navigate("/"); 
        };
    }, [navigate, id]);

    if (!(isMobile && parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) return;
 
    return (
        <div className="w-screen h-screen">
            <TopNavBar />
            {/* <div className="fixed w-full text-center text-white text-4xl py-7 px-5">
                {VMFloor2ObjectsList[id]?.title}
            </div> */} 
            <DetailArrows 
                floor="floor2"
                currentPageNumber={parseInt(id)}
                lastPageNumber={lastPageNumber}
            />
            
            <Floor2DetailScene 
                model={VMFloor2ObjectsList[id]?.model}
                canvasStyle={{ outline: "none" }}
            />

            <Floor2AIDocent 
                docentVideoSrc={VMFloor2ObjectsList[id]?.docentVideoSrc} 
                description={Floor2DescriptionsList[id]?.korean}
            />
        </div>
    )
};

export default VMFloor2Detail;