import { useEffect, useContext, useRef } from "react";
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Resize from "../../libs/Resize";
import loadAsyncGLTF from "../../libs/loadAsyncGLTF";
// import paleozoicBg from "../../img/paleozoic-bg-HDRI.png";
// import mesozoicBg from "../../img/mesozoic-bg-HDRI.png";
import cenozoicBg from "../../img/cenozoic-bg-HDRI.png";
import { useNavigate } from "react-router-dom";
import StorageContext from "../../utils/contexts/StorageContext";
// import gsap from "gsap";


// let width = window.innerWidth;
// let height = window.innerHeight;

// eslint-disable-next-line
// const skyBg = {
//   "paleozoic": paleozoicBg,
//   "mesozoic": mesozoicBg,
//   "cenozoic": cenozoicBg,
// };

const MobileMainSceneComp = ({ canvasStyle, mainModelsList }) => {

  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const storageContext = useContext(StorageContext); 
  // let moveNum = 0;
  
  useEffect(() => {

    const createScene = () => { 

      const mixers = [];
      const container = containerRef.current;
      const canvas = canvasRef.current;

      /* <createScene> FUNCTION #1. renderScene() */
      const renderScene = async () => {

        const { width, height } = container.getBoundingClientRect();

        /* 1. Setup scene */
        const scene = new THREE.Scene();
        scene.background = new THREE.Color("#eee"); // scene background color

        /* 2. Setup camera */
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.01, 10000);
        
        // #1. <Avatar>
        camera.position.set(-20, 70, 50);

        // #2. <Spinosaurus>
        // camera.position.set(-20, 70, -60);

        // #3. <Helicoprion>
        // camera.position.set(-20, 70, -200);

        // #4 <Mammoth>
        // camera.position.set(-20, 70, -550);

        /* 3. Setup renderer */
        const renderer = new THREE.WebGLRenderer({
          canvas,
          antialias: true,
        });

        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;

        ///////////////////////////////////////////////////////////////
        renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.toneMappingExposure = 0.7;
        scene.environment = THREE.sRGBEncoding;
        ///////////////////////////////////////////////////////////////


        /* 4. Add 3D Object(s) */

        /* Add <Plane> */
        // const planeGeometry = new THREE.CylinderGeometry(400, 400, 5, 100);
        // const planeMaterial = new THREE.MeshPhongMaterial({
        //     color: "blue",
        // });
        // const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        // plane.position.set(0, -5, 0);
        // plane.receiveShadow = true;
        // scene.add(plane);


        ///////////////////////////////////////////////////////////////
        /* Add <Sky> */
        // const imageLoader = new THREE.TextureLoader();
        // const skyTexture = await imageLoader.loadAsync(skyBg[model.modelBg]);
		
        // const skyGeometry = new THREE.SphereGeometry(300, 50, 32);
        // const skyMaterial = new THREE.MeshBasicMaterial({ 
        //   map: skyTexture,
        //   side: THREE.BackSide
        // });
    
        // const sky = new THREE.Mesh(skyGeometry, skyMaterial);
        // sky.position.set(0, 50, 0);
        // scene.add(sky);
        ///////////////////////////////////////////////////////////////


        /* Add AmbientLight */
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        /* Add PointLight */
        const pointLightColor = 0xffffff;
        const pointLightIntensity = 1;
        const pointLight = new THREE.PointLight(pointLightColor, pointLightIntensity);
        pointLight.castShadow = true;
        // pointLight.shadow.mapSize.width = 2048;
        // pointLight.shadow.mapSize.height = 2048;
        // pointLight.shadow.radius = 5;
        pointLight.position.set(40, 120, 40);
        scene.add(pointLight);


        ///////////////////////////////////////////////////////////////
        /* Load GLB Model(s) */

        await Promise.all([

          // // Model #1
          // loadAsyncGLTF({ 
          //   scene, 
          //   mixers, 
          //   modelSrc: mainModelsList["1"].model.modelSrc,
          //   animationName: mainModelsList["1"].model.animationName,
          //   position: mainModelsList["1"].model.position,
          //   scale: mainModelsList["1"].model.scale,
          //   rotation: mainModelsList["1"].model.rotation,
          // }),

          // // Model #2
          // loadAsyncGLTF({
          //   scene, 
          //   mixers, 
          //   modelSrc: mainModelsList["2"].model.modelSrc,
          //   animationName: mainModelsList["2"].model.animationName,
          //   position: mainModelsList["2"].model.position,
          //   scale: mainModelsList["2"].model.scale,
          //   rotation: mainModelsList["2"].model.rotation,
          // }),

          // // Model #3
          // loadAsyncGLTF({ 
          //   scene, 
          //   mixers, 
          //   modelSrc: mainModelsList["3"].model.modelSrc,
          //   animationName: mainModelsList["3"].model.animationName,
          //   position: mainModelsList["3"].model.position,
          //   scale: mainModelsList["3"].model.scale,
          //   rotation: mainModelsList["3"].model.rotation,
          // }),

          // Model #4
          loadAsyncGLTF({ 
            scene, 
            mixers, 
            modelSrc: mainModelsList["4"].model.modelSrc,
            animationName: mainModelsList["4"].model.animationName,
            position: mainModelsList["4"].model.position,
            scale: mainModelsList["4"].model.scale,
            rotation: mainModelsList["4"].model.rotation,
          }),

        ]);
        ///////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////
        const loader = new THREE.TextureLoader();
        const skyTexture = await loader.loadAsync(cenozoicBg);
        const skyGeometry = new THREE.SphereGeometry(1000, 50, 32);
        const skyMaterial = new THREE.MeshPhongMaterial({
            map: skyTexture,
            side: THREE.BackSide,
        });

        const sky = new THREE.Mesh(skyGeometry, skyMaterial);
        sky.position.set(0, 50, 0);
        sky.rotation.set(0, 0, 0);
        sky.receiveShadow = true;
        sky.castShadow = true;
        sky.name = "sky";
        scene.add(sky);
        ///////////////////////////////////////////////////////////////


        /* 5. Setup orbital controls */
        // const controls = new OrbitControls(camera, canvas);
        // controls.enableKeys = false;
        // controls.enablePan = false;
        // controls.enableZoom = true;
        // controls.enableDamping = true;
        // controls.enableRotate = true;
        // controls.autoRotate = true;
        // controls.minPolarAngle = 0 * (Math.PI / 180);
        // controls.maxPolarAngle = 70 * (Math.PI / 180); 
        // controls.minDistance = 10;
        // controls.maxDistance = 400; 


        /* 6. run animate() -> <requestAnimationFrame> */
        const clock = new THREE.Clock();

        const animate = () => {
          // controls.update(); // orbitControls.autoRotate is enabled so orbitControls.update must be called inside animation loop.
          requestAnimationFrame(animate);
          
          const delta = clock.getDelta();

          mixers?.forEach((mixer) => {
            mixer.update(delta);
          })

          // camera.lookAt(scene.position);
          camera.updateProjectionMatrix();

          renderer.render(scene, camera);

        };


        animate();

        /* Adjust resized Screen width & height */
        const resize = new Resize(camera, renderer);
        resize.start();

      };

      return navigator.onLine
        ? renderScene() 
        : console.error("Please make sure that you are connected to the Internet!");

    };

    /* FINALLY!! - run createScene() */
    createScene();

  }, [mainModelsList]);

  return (
    <div 
      ref={containerRef}
      className="w-full h-full"
    >

        <div
            className="flex items-center justify-start fixed w-screen h-screen whitespace-pre-line leading-relaxed text-gray-800 text-3xl font-light p-3 bg-transparent bg-opacity-30"
        >
            {/* bg-black p-3 bg-opacity-40 rounded-lg */} 
            <div className="flex-col">
                <div className="p-2 text-2xl leading-normal">
                    {"Welcome to the"}
                </div>
                <div className="p-2 text-3xl leading-relaxed">
                    {"Seodaemun\nMetaverse\nMuseum"}
                </div>
            </div>
        </div>

        {/* divide-y-8 */}
        <div className="fixed w-full h-[20%] bottom-7 p-5">
            <button 
              className="block mb-2"
              onClick={() => navigate("/virtual-museum/floor1/1")}
            >
              {storageContext.localLanguage !== "english" ? "1층 박물관" : "Museum 1st Floor"}
            </button>
            <button 
              className="block mb-2"
              onClick={() => navigate("/virtual-museum/floor2/1")}
            >
              {storageContext.localLanguage !== "english" ? "2층 박물관" : "Museum 2nd Floor"}
            </button>
            <button 
              className="block mb-2"
              onClick={() => navigate("/virtual-museum/floor3/1")}
            >
              {storageContext.localLanguage !== "english" ? "3층 박물관" : "Museum 3rd Floor"}
            </button>
            <button 
              className="block"
              onClick={() => navigate("/experience")}
            >
              {storageContext.localLanguage !== "english" ? "타임메타 체험관" : "Time Meta Experience Hall"}
            </button>
        </div>

        {/* <div className="fixed w-screen h-screen flex items-center justify-center">
            <button
                className="absolute px-4 py-2 rounded-lg bottom-5 z-[1000] text-lg bg-rose-500 bg-opacity-90 text-gray-100 cursor-pointer"
                onClick={() => {

                    console.log("camera.position.z: ", camera.position.z);
                    
                    const GAP = -700;
                    const NUMBER_OF_MODELS = 4;
                    if (camera.position.z >= (GAP * (NUMBER_OF_MODELS - 1) * 1.1) && camera.position.z <= (GAP * (NUMBER_OF_MODELS - 1) * 0.9)) {
                        moveNum = 0;
                    }
                    else {
                        moveNum = camera.position.z + GAP;
                    }

                    gsap.to(camera.position, {
                        duration: 1.9,
                        delay: 0.2,
                        z: moveNum,
                        ease: "Power4.easeInOut",
                    });
                }}
            >
                CLICK
            </button>
        </div> */}

        <canvas
            ref={canvasRef}
            id="canvas"
            width="100%"
            height="100%"
            style={{ ...canvasStyle }}
        />
    </div>
  ); 
};

export default MobileMainSceneComp;