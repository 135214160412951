import axios from "axios";



/*
    <SDM_LOCATION_API> -> 방문자 네트워크 분석
*/ 

export const getWebVisitorSessionNetworkAPI = async () => {
    return axios.get(`${process.env.REACT_APP_SDM_LOCATION_API_ENDPOINT}`);
}; 





/*
    <SDM_WEB_API> -> 방문자 회원가입 및 로그인 처리
*/ 

export const postWebSignupUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_WEB_API_ENDPOINT + "/api/v1/user/signup";
  
    return axios(`${API_ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
};

export const postWebLoginUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_WEB_API_ENDPOINT + "/api/v1/user/login";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
}; 

export const postWebVerifyEmailUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_WEB_API_ENDPOINT + "/api/v1/user/verify-email";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        }, 
        data,
    });
};

export const postWebRequestResetPasswordUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_WEB_API_ENDPOINT + "/api/v1/user/create-reset-password-token";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
};

export const postWebResetPasswordUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_WEB_API_ENDPOINT + "/api/v1/user/reset-password";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
};





/*
    <SDM_VISITOR_API> -> 방문자 분석 데이터 적재
*/ 

export const postWebVisitorLocalLocalIdAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/local/update-local";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
}; 
  
export const postWebVisitorLocalSessionsAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/local/update-sessions";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  
export const postWebVisitorLocalReferrerAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/local/update-referrer";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};


export const postWebVisitorLocalIsUnityDownloadedAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/local/update-downloaded";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  
export const postWebVisitorLocalUsersAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/local/update-users";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  



export const postWebVisitorSessionSessionIdAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-session";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
export const postWebVisitorSessionNetworkAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-network";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionDeviceAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-device";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionCurrentVisitAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-current-visit";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
export const postWebVisitorSessionPreviousVisitAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-previous-visit";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionReferrerAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-referrer";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionUsersAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_SDM_VISITOR_API_ENDPOINT + "/api/v1/web/session/update-users";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};