import React, { useContext } from "react";
import bg from "./img/premium-metaverse-museum-bg.png";
import StorageContext from "../../utils/contexts/StorageContext";
import TopNavBar from "../../components/TopNavBar";
import { isWindows } from "react-device-detect";
// import { clsx } from '../../libs/mergeClassNames';

const UnityDownloadV1 = () => {
  
  const storageContext = useContext(StorageContext); 

  return (
    <div>
      <TopNavBar />  
      <div className="fixed w-screen h-screen bg-gray-900 bg-opacity-90 inset-0 flex justify-center items-center">
          <img
            src={bg}
            alt="bg"
            className="opacity-90 absolute object-cover w-screen h-screen inset-0 top-0 left-0"
          /> 

          <div className="whitespace-pre-line relative text-gray-100 p-10">
            <p className="text-center text-xl sm:text-2xl md:text-3xl p-5 mb-5">
              {storageContext.localLanguage !== "english" 
                ? '"서대문 메타버스 박물관에 오신 것을 환영합니다"' 
                : '"Welcome to the Seodaemun Metaverse Museum"'
              }
            </p>
            <ul className="list-none text-sm sm:text-base md:text-lg [&>*]:p-3 p-3">
              <li>
                {storageContext.localLanguage !== "english" 
                  ? "본 메타버스 박물관은 'PC 전용 프리미엄 메타버스 박물관'과 '모바일 전용 웹 메타버스 박물관을 함께 제공합니다.'"
                  : "Our Metaverse Museum provides both the PC-only Premium Metaverse Museum and\nthe Mobile-only Web Metaverse Museum"
                }
              </li>
              <li>
                {storageContext.localLanguage !== "english" 
                  ? "프리미엄 메타버스 박물관은 '프리미엄 메타버스 박물관 다운로드' 버튼을 클릭하시면 설치가 진행됩니다."
                  : "The Premium Metaverse Museum will be installed by clicking the\n'Download Premium Metaverse Museum' button."
                }
              </li>
              <li>
                {storageContext.localLanguage !== "english" 
                  ? "현재 프리미엄 메타버스 박물관은 Windows PC 환경에서만 제공됩니다."
                  : "Currently, the Premium Metaverse Museum is only available in Windows PC environments."
                }
              </li>
            </ul>
            <div className="flex justify-center items-center mt-14">
            
            {isWindows &&
            <a
              href="https://kr.object.ncloudstorage.com/sdm-unity-file/sdm_museum_setup_v1.6.zip"
              target="_blank"
              rel="noreferrer"
              onClick={() => storageContext.setIsUnityDownloaded(true)}
              className="text-base sm:text-lg md:text-xl rounded-lg p-5 animate-bounce bg-green-600 text-gray-200 bg-opacity-90 hover:bg-green-700 hover:bg-opacity-100"
            >
              <p>{storageContext.localLanguage !== "english" ? "프리미엄 메타버스 박물관 다운로드" : "Download Premium Metaverse Museum"}</p>
              <div className="mt-1 flex items-center justify-center"> 
                <p className="text-sm">{storageContext.localLanguage !== "english" ? "(업데이트: 2022-11-21)" : "(Update: 2022-11-21)"}</p>
              </div>
            </a>}

          </div>
          </div>
      </div>
    </div>
  );
};

export default UnityDownloadV1;
