import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

const loadAsyncGLTF = async ({
    modelSrc,
    scene, mixers, animationName,
    position, scale, rotation
}) => {

    try {

        if (!modelSrc || !scene || !mixers || !animationName || !position || !scale || !rotation) throw new Error("params are required!");

        const loader = new GLTFLoader();

        const gltf = await loader.loadAsync(modelSrc);
        if (!gltf?.scene) throw new Error("<gltf.scene> is required!");

        gltf.scene.traverse((child) => {
            
            //////////////////////////////////////////////
            //////////////////////////////////////////////
            child.frustumCulled = false; 
            //////////////////////////////////////////////
            //////////////////////////////////////////////

            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        gltf.scene.mixer = new THREE.AnimationMixer(gltf.scene);
        mixers.push(gltf.scene.mixer);

        if (!(mixers?.length > 0)) throw new Error("<mixers.length> is NOT greater than 0");

        const clip = THREE.AnimationClip.findByName(gltf.animations, animationName);
        const action = gltf.scene.mixer.clipAction(clip);
        action.play();

        const [posX, posY, posZ] = position;
        gltf.scene.position.set(posX, posY, posZ);

        const [scaleX, scaleY, scaleZ] = scale;
        gltf.scene.scale.set(scaleX, scaleY, scaleZ);

        const [rotationX, rotationY, rotationZ] = rotation;
        gltf.scene.rotation.set(rotationX, rotationY, rotationZ);

        scene.add(gltf.scene);

        return gltf;

    }

    catch(error) {
        console.log("error: ", error);
        return Promise.reject(error);
    }

};

export default loadAsyncGLTF;