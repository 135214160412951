import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VMFloor3ObjectsList from "./VMFloor3ObjectsList";
import Floor3DetailScene from "../../components/Floor3DetailScene";
import Floor3AIDocent from "../../components/Floor3AIDocent";
import Floor3DescriptionsList from "./VMFloor3DescriptionsList";
import TopNavBar from "../../components/TopNavBar";
import { isMobile } from "react-device-detect"; 
import DetailArrows from "../../components/DetailArrows";

const lastPageNumber = 9; // <Floor #3>의 총 전시물 개수

const VMFloor3Detail = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        if (!(parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) { // 전시물 개수보다 1이상 <lastPageNumber>이하가 "아닌" 경우
            navigate("/"); 
        };
    }, [navigate, id]);

    if (!(isMobile && parseInt(id) >= 1 && parseInt(id) <= lastPageNumber)) return;
 
    return (
        <div className="w-screen h-screen">
            <TopNavBar />
            {/* <div className="fixed w-full text-center text-white text-4xl py-7 px-5">
                {VMFloor3ObjectsList[id]?.title}
            </div> */}

            <DetailArrows 
                floor="floor3"
                currentPageNumber={parseInt(id)}
                lastPageNumber={lastPageNumber}
            />

            <Floor3DetailScene 
                model={VMFloor3ObjectsList[id]?.model}
                canvasStyle={{ outline: "none" }}
            />

            <Floor3AIDocent 
                docentVideoSrc={VMFloor3ObjectsList[id]?.docentVideoSrc} 
                description={Floor3DescriptionsList[id]?.korean}
            />
        </div>
    )
};

export default VMFloor3Detail;