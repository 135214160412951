import React, { useState, useEffect, useContext } from "react"; // , useEffect
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query"; // useQuery, useQueryClient 
import StorageContext from "../../utils/contexts/StorageContext";
import ModalContext from "../../utils/contexts/ModalContext";
import TopNavBar from "../../components/TopNavBar";
import { LoginSuccessModal, LoginFailModal } from "../../components/Modal";
import { postWebLoginUserAPI } from "../../utils/visitorLogAxiosAPI";
import { encryptWithAES } from "../../utils/crypto";
// import backgroundVideoRight from "./videos/walking-dinosaur.mp4";

const Login = () => {
    const storageContext = useContext(StorageContext); 
    const navigate = useNavigate();

    const [accountId, setAccountId] = useState("");
    const [isSuccessUpdated, setIsSuccessUpdated] = useState(false);
    const [isResponseSuccess, setIsResponseSuccess] = useState(false);
    const [isLoginModalRequired, setIsLoginModalRequired] = useState(false);

    const { mutate, isLoading } = useMutation(postWebLoginUserAPI, { // data, 
      onSuccess: (data) => {
          // console.log("useMutation Success response data: ", data?.data);
          if (data?.data?.ok === true) { 
            setIsResponseSuccess(true); 
            setIsLoginModalRequired(true);
          }
      },
      onError: (error, variables) => {
          // console.log("useMutation Error response data: ", error?.response?.data);
          if (error?.response?.data?.ok === false) { 
            setIsResponseSuccess(false); 
            setIsLoginModalRequired(true);
          }
      },
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ // reset, watch, setError, setValue
        mode: "onChange", // mode: "all", "onBlur", "onChange", "onTouched"
        defaultValues: {
            accountId: "",
            password: ""
        }
    });

    const onValid = (submittedFormData) => {
      if (isLoading) return;

      const { accountId, password: typedPassword } = submittedFormData;
      if (!accountId || !typedPassword) return;
      setAccountId(accountId);

      const updateData = {
        accountId, 
        typedPassword
      };

      const encryptedUpdateData = encryptWithAES(updateData);
      if (!encryptedUpdateData) return;

      mutate({ data: encryptedUpdateData });

    };
    
    const onInvalid = (errors) => {
        console.log("errors: ", errors);
        console.log("This is onInvalid Function!");
    };

    useEffect(() => {

      if (!isSuccessUpdated && isResponseSuccess && accountId) {

        const newLoggedInAt = {
          accountId,
          isLoggedIn: true,
          loggedInAt: new Date().toISOString(),
        };

        const encryptedLoggedInAt = encryptWithAES(newLoggedInAt);
        if (!encryptedLoggedInAt) return;

        storageContext.setSessionLoggedInAt(encryptedLoggedInAt);
        setIsSuccessUpdated(true);

      };

      // if (!isResponseSuccess) { } // *** 로그인 실패한 경우 (not necessary)! *** 
    }, [isSuccessUpdated, isResponseSuccess, accountId, storageContext]);

    return (
      <div>
        <TopNavBar /> 
        <div className="flex fixed w-screen h-screen">
          <div className="flex items-center flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <h2 className="text-center mt-6 text-5xl font-thin tracking-tight text-gray-500">
                {storageContext.localLanguage !== "english" ? "회원 로그인": "LOGIN"}
              </h2>
  
              {/* FORM CONTENT BEGINS */}
              <div className="mt-14">
                  <form 
                      onSubmit={handleSubmit(onValid, onInvalid)}
                      className="space-y-6 mb-7"
                  >
                  <div>
                      <label 
                          htmlFor="accountId" 
                          className="block text-lg font-extralight text-gray-700"
                      >
                        {storageContext.localLanguage !== "english" ? "이메일 또는 계정 ID": "Email or Account ID"}
                      </label>
                    <div className="mt-1">
                      <input
                          id="accountId"
                          name="accountId"
                          label="accountId"
                          type="text"
                          autoComplete="on" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                          placeholder="ex. sdm@gmail.com"
                          // required
                          {...register("accountId", {
                              required: storageContext.localLanguage !== "english" ? "이메일 또는 계정 ID는 필수입니다.": "Either Email or Account ID is required.", // "이메일 또는 ID는 필수입니다.",
                              minLength: {
                                  message: storageContext.localLanguage !== "english" ? "계정 정보는 4자리 이상이여야 합니다.": "Account information must be at least 4 characters.", // "이메일 주소는 7자리 이상이여야 합니다.",
                                  value: 4,
                              },
                              // pattern: /[A-Za-z]{3}/,   // NOTE: A RegExp object with the /g flag keeps track of the lastIndex where a match occurred.
                              
                              // pattern: {
                              //     value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 
                              //     message:"Sorry, this is a WRONG email format."
                              // },

                              // validate: {
                              //     notContainsAt: (value) => value.includes("@") || "Sorry, a valid email address should contain '@'.", // if return false -> validation error!
                              //     // positive: v => parseInt(v) > 0,
                              //     // lessThanTen: v => parseInt(v) < 10,
                              //     // checkUrl: async () => await fetch(),
                              // }
                          })}
                          className="text-gray-600 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                      />
                      <p className="mt-2 text-sm font-extralight text-red-500">{errors?.accountId?.message}</p>
                    </div>
                  </div>

                  <div className="space-y-1">
                      <label 
                          htmlFor="password" 
                          className="block text-lg font-extralight text-gray-700"
                      >
                        {storageContext.localLanguage !== "english" ? "비밀번호": "Password"}
                      </label>

                      <div className="mt-1">

                          <input
                              id="password"
                              name="password"
                              label="password"
                              type="password"
                              autoComplete="current-password"
                              placeholder="******"
                              // required
                              {...register("password", {
                                  required: storageContext.localLanguage !== "english" ? "비밀번호는 필수입니다.": "Password is required.", // "비밀번호는 필수입니다.",
                                  minLength: {
                                      message: storageContext.localLanguage !== "english" ? "비밀번호는 6자리 이상이여야 합니다.": "Password must be at least 6 characters.", // "비밀번호는 6자리 이상이여야 합니다.",
                                      value: 6,
                                  },
                                  // validate: {
                                  //     containsLondon: (value) => !value.includes("London") || "Sorry, 'London' is not allowed", // if return false -> validation error!
                                  // }
                              })}
                              className="text-gray-600 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                          />

                          <p className="mt-2 text-sm font-extralight text-red-500">{errors?.password?.message}</p>
                      </div>
                  </div>
                  <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-lg font-light text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                    >
                      {storageContext.localLanguage !== "english" ? "로그인": "Login"}
                  </button>
                </form>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <button
                      className="text-sm font-light text-cyan-600 hover:text-cyan-500"
                      onClick={() => { // event
                        // event.preventDefault();
                        navigate("/email-signup");
                      }}
                    >
                      {storageContext.localLanguage !== "english" ? "회원가입": "Create Account"}
                    </button>
                  </div>

                  <div className="text-sm">
                    <button 
                      className="ml-2 block font-light text-sm text-red-400 hover:text-red-300"
                      onClick={() => {
                        navigate("/request-reset-password");
                      }}
                    >
                      {storageContext.localLanguage !== "english" ? "비밀번호를 잊으셨나요?": "Forgot Password?"}
                    </button>
                  </div>
                </div>


              </div>
              {/* FORM CONTENT ENDS */}

            </div>
          </div>
          
          <div className="relative hidden w-0 flex-1 lg:block">
            <video 
                src={process.env.PUBLIC_URL + "/videos/login/walking-dinosaur.mp4"} 
                autoPlay muted loop={true} playsInline 
                className="absolute inset-0 h-full w-full object-cover"
            />
          </div>
        </div>

        <ModalContext.Provider value={{ isLoginModalRequired, setIsLoginModalRequired }}>
          {(isResponseSuccess)
            ? <LoginSuccessModal message={storageContext.localLanguage !== "english" ? "로그인이 성공했습니다!" : "Login succeeded!"} moveToPage="/download" /> 
            
            : (!isResponseSuccess)
            ? <LoginFailModal message={storageContext.localLanguage !== "english" ? "로그인이 실패했습니다!" : "Login failed!"} />
          
            : null}
            
          {/* {isResponseSuccess && <LoginSuccessModal message="로그인이 성공했습니다!" moveToPage="/metaverse-download" /> }
          {!isResponseSuccess && <LoginFailModal message="로그인이 실패했습니다!" /> } */}
        </ModalContext.Provider>
      </div>
    )
};

export default Login;
  