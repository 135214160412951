import React, { useContext } from "react"; // { useState, useEffect }
import StorageContext from "../../utils/contexts/StorageContext";

import TopNavBar from "../../components/TopNavBar";
import Slider from "../../components/Slider";
import { isMobile } from "react-device-detect";
import MobileMainPopup from "../../components/MobileMainPopup";
import MobileMain from "../../pages/MobileMain"; 

const PCMain = () => {

  const storageContext = useContext(StorageContext); 

  return (
    <div>
      <TopNavBar />
      {isMobile && <MobileMainPopup />}
      {isMobile && <MobileMain />} 

      {!isMobile && 
        <main className="max-h-screen overflow-y-scroll snap snap-y snap-mandatory">
          <section className="w-full h-screen snap-start">
            <Slider 
              id={1} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "서대문자연사박물관" : "SEODAEMUN"}
              href="/"
              videoSrc={process.env.PUBLIC_URL + "/videos/main/bg-video-unity-teaser-low.mp4"}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>
        </main>}
    </div>
  );
};

export default PCMain;
