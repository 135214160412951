import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StorageContext from "../../utils/contexts/StorageContext";

import TopNavBar from "../../components/TopNavBar";
import Slider from "../../components/Slider";
import { isMobile } from "react-device-detect";

const ExperienceHallMain = () => { 

  const storageContext = useContext(StorageContext); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
        navigate("/"); 
    };
}, [navigate]);

  if (!isMobile) return null;

  return (
    <div>
      <TopNavBar />

      <div className="fixed w-screen bottom-2 z-50 text-gray-200 text-center text-[0.6rem]">
        {storageContext.localLanguage !== "english" ? "스크롤" : "SCROLL"} &#8595;
      </div>

      <main className="max-h-screen overflow-y-scroll snap snap-y snap-mandatory">
          <section className="w-full h-screen snap-start">
            <Slider 
              id={3} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "고생대 시대" : "Paleozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/paleozoic-era.html"
              videoSrc={process.env.PUBLIC_URL + "/videos/main/bg-video-paleozoic-low.mp4"}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>
          <section className="w-full h-screen snap-start">
            <Slider 
              id={4} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "중생대 시대" : "Mesozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/mesozoic-era.html"
              videoSrc={process.env.PUBLIC_URL + "/videos/main/bg-video-mesozoic-low.mp4"}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>

          <section className="w-full h-screen snap-start">
            <Slider 
              id={5} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "신생대 시대" : "Cenozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/cenozoic-era.html"
              videoSrc={process.env.PUBLIC_URL + "/videos/main/bg-video-cenozoic-high.mp4"}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>
      </main>
    </div>
  );
};

export default ExperienceHallMain;
