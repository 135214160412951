const VMFloor2Descriptions = {
    "1": {
        korean: {
            name: "긴털 메머드",
            englishName: "Woolly Mammoth",
            scientificName: "Mammuthus primigenius",
            geologicAge: "15만~4천년 전",
            distribution: "아시아, 유럽, 미국의 북부지역",
            content: "현생 아프리카코끼리의 크기와 비슷하며, 어깨까지의 높이가 2.7 ~ 3m 정도이고, 최대 무게는 6톤 정도로 알려져 있습니다. 마지막 빙하기의 추위에서도 잘 견딜 수 있었던 이유는 열 손실을 최소화하기 위하여 귀의 크기가 작았던 점, 그리고 긴 털로 체온을 지킬 수 있었던 점을 들 수 있습니다. 현생 코끼리와는 달리 긴털 매머드는 긴 상아가 있는 점이 특징이며 초기 인류들과 함께 생활을 했기 때문에 긴털 매머드의 뼈와 상아는 초기 인류들에 의해 집을 짓거나 도구나 장신구를 만들 때 사용되었습니다.",
        },
    },
    "2": {
        korean: {
            name: "검치호랑이",
            englishName: "Saber-toothed Tiger",
            scientificName: "Smilodon fatalis",
            geologicAge: "160만~1만년 전",
            distribution: "북미와 남미대륙",
            content: "검치 호랑이는 위턱에 나 있는 단검 같은 모양의 커다란 송곳니 때문에 붙은 이름이며 짧은 꼬리, 강인한 다리, 근육질의 목과 18 ~20cm에 달하는 긴 송곳니를 가진 것이 특징입니다. 검치 호랑이는 들소, 사슴, 미국 낙타, 그리고 말을 포함한 다양한 대형 동물을 사냥했습니다. 크기는 사자와 비슷했을 것으로 추정되며 1930년대 이후 많은 화석이 발견되었습니다.",
        }
    },
    "3": {
        korean: {
            name: "스피노사우루스",
            englishName: "Spinosaurus",
            scientificName: "Spinosaurus",
            geologicAge: "1억 1,200만~9,700만년 전",
            distribution: "북아프리카 지역",
            content: "수각류인 스피노사우루스는 ‘가시 도마뱀’이란 의미를 가지고 있습니다. 가장 큰 특징은 등 위로 부채모양의 돛을 가지고 있다는 점이며 크기는 10~15m에 몸무게는 4~5톤 정도였을 것으로 추정됩니다. 등뼈가 길어지면서 생긴 돛은 체온 조절과 다른 공룡에게 위협적으로 느낄 수 있게 몸을 더 크게 보이는 기능과 효과를 가지고 있었습니다.",
        }
    },
    "4": {
        korean: {
            name: "스테고사우루스 ",
            englishName: "Stegosaurus",
            scientificName: "Stegosaurus",
            geologicAge: "1억 5,600만~1억 4,500만년 전",
            distribution: "미국 지역",
            content: "등을 따라 10~11쌍의 얇은 골 판들이 발달하였고 꼬리 끝에는 두 쌍의 기다란 창모양의 뼈가 뒤로 튀어나와 있어 무기로 사용되었습니다. 등의 골 판 속에는 수많은 미세한 혈관이 발달해 체온 조절 역할을 한 것으로 보입니다. 5~9m에 달하는 거대한 몸집에 비해 호두 알 크기에 달걀 정도 되는 무게를 가진 작은 뇌를 가졌습니다.",
        }
    },
    "5": {
        korean: {
            name: "티라노사우루스",
            englishName: "Tyrannosaurus",
            scientificName: "Tyrannosaurus",
            geologicAge: "6,700만~6,500만년 전",
            distribution: "캐나다, 미국, 아시아 지역",
            content: "수각류인 티라노사우루스는 가장 잘 알려진 공룡으로써 티라노사우루스라는 이름의 의미는 ‘폭군 도마뱀’이라는 의미를 가지고 있습니다. 크기는 10~12m, 몸무게는 4.5~7톤 정도이며 트리케라톱스의 뼈를 부수고 쪼갤 정도로 무섭고 날카로운 원뿔형 이빨을 가지고 있었으며 길고 잘 발달된 뒷다리와 강한 근육을 가진 포식자 중 최고의 포식자였습니다.",
        }
    },
    "6": {
        korean: {
            name: "트리케라톱스",
            englishName: "Triceratops",
            scientificName: "Triceratops",
            geologicAge: "6,800만~6,500만년 전",
            distribution: "북미대륙",
            content: "이들은 몸길이 7.9 ~ 9.0m, 키 2.9 ~ 3.0m, 몸무게 6.1 ~ 12.0톤에 달하는 거대한 공룡이었습니다. 커다란 머리에는 약 1m에 달하는 3개의 뿔이 돋아나 있으며 머리의 뒤편으로 부채처럼 펼쳐진 프릴이 트리케라톱스의 목을 감싸고 있으며, 이 뿔과 프릴은 티라노사우루스와 같은 육식공룡의 공격으로부터 자신을 보호하는 무기로 사용되었던 것으로 보입니다.",
        }
    },
    "7": {
        korean: {
            name: "아노말로카리스",
            englishName: "Anomalocaris",
            scientificName: "Anomalocaris",
            geologicAge: "캄브리아기 (약 5억년 전)",
            distribution: "북미, 호주, 및 중국 지역",
            content: "아노말로카리스는 ‘이상한 새우’라는 뜻을 가진 고대 해양 생물로 약 5억년 전 원시 지구의 바다에 출현했습니다. 일반적인 크기는 60cm정도지만 어떤 종류는 최대 2m에 이르렀을 것으로 추정되며 캄브리아기 생태계의 최상위 지배자로 군림했을 것으로 보고 있습니다.",
        }
    },
    "8": {
        korean: {
            name: "둔클레오스테우스",
            englishName: "Dunkleosteus",
            scientificName: "Dunkleosteus",
            geologicAge: "데본기 (약 3억 6,000만년 전)",
            distribution: "북미, 유럽, 모로코 지역",
            content: "고생대 거대 바다 어류입니다. 크기는 10미터에 이르며 그 무게는 4톤에 이르렀을 것으로 추측하고 있습니다. 데본기 바다의 최강의 포식자로 군림했을 것으로 보고 있으며 그 당시 바다에 살았던 절지동물, 어류, 연체동물 등을 잡아먹고 살았습니다.",
        }
    },
    "9": {
        korean: {
            name: "헬리코프리온",
            englishName: "Helicoprion",
            scientificName: "Helicoprion",
            geologicAge: "페름기 (2억 5000만년 전)",
            distribution: "북미, 아시아, 유럽, 인도 지역",
            content: "전기회전톱 모양의 독특한 이빨구조로 널리 알려진 원시 상어의 일종입니다. 크기는 약 3~6미터로 추정되며 특이한 나선모양의 이빨은 주로 암모나이트를 부수어 먹는데 쓰인 것으로 보입니다. 하지만 이 나선모양의 이빨의 명확한 용도와 구조는 정확히 밝혀지지 않았습니다. 페름기 이후에 번성한 다른 해양 파충류 들과의 충돌에서 밀려나 멸종된 것으로 추정됩니다.",
        }
    },
    "10": {
        korean: {
            name: "텔로두스",
            englishName: "Thelodus",
            scientificName: "Thelodus",
            geologicAge: "실루리아기 (4억년 전)",
            distribution: "유럽, 아시아, 북미 지역",
            content: "고생대 무악류 어류입니다. 크기는 15~20센티미터 정도인 것으로 보이며 플랑크톤과 같은 작은 먹이를 주식으로 삼은 것으로 보입니다. 피부가 갑피로 덮여 있지 않았지만 시간이 흐르면서 갑피를 가진 어류로 진화했다고 추정하고 있습니다.",
        }
    },
};

export default VMFloor2Descriptions;