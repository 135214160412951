const VMFloor1ObjectsList = {
    "1": {
        title: "#1: Mallard",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor1/floor1-ai-docent-mallard.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor1/Mallard.glb",
            modelBg: "cenozoic",
            animationName: "Animation",
            position: [0, 10, 0], 
            scale: [80, 80, 80], 
            rotation: [0, 10, 0],
        },
    },
    "2": {
        title: "#2: Red FOX",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor1/floor1-ai-docent-red-fox.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor1/Fox.glb",
            modelBg: "cenozoic",
            animationName: "Take 001",
            position: [0, 10, 0], 
            scale: [0.65, 0.65, 0.65], 
            rotation: [0, 10, 0],
        },
    },
    "3": {
        title: "#3: DANDELION",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor1/floor1-ai-docent-dandelion.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor1/Dandelion.glb",
            modelBg: "cenozoic",
            animationName: "Scene",
            position: [0, 10, 0], 
            scale: [5, 5, 5], 
            rotation: [0, 10, 0],
        },
    },
}

export default VMFloor1ObjectsList;