import { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Resize from "../../libs/Resize";
import loadAsyncGLTF from "../../libs/loadAsyncGLTF";
import paleozoicBg from "../../img/paleozoic-bg-HDRI.png";
import mesozoicBg from "../../img/mesozoic-bg-HDRI.png";
import cenozoicBg from "../../img/cenozoic-bg-HDRI.png";

// let width = window.innerWidth;
// let height = window.innerHeight;

const skyBg = {
  "paleozoic": paleozoicBg,
  "mesozoic": mesozoicBg,
  "cenozoic": cenozoicBg,
};

const Floor2DetailScene = ({ canvasStyle, model }) => { // , ...rest 

  const containerRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {

    const createScene = () => {

      const mixers = [];
      const container = containerRef.current;
      const canvas = canvasRef.current;


      /* <createScene> FUNCTION #1. renderScene() */
      const renderScene = async () => {

        const { width, height } = container.getBoundingClientRect();

        /* 1. Setup scene */
        const scene = new THREE.Scene();
        
        /* 2. Setup camera */
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 10000);
        camera.position.set(0, -30, -120);

        /* 3. Setup renderer */
        const renderer = new THREE.WebGLRenderer({
          canvas,
          antialias: true,
        });

        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;

        ///////////////////////////////////////////////////////////////
        renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.toneMappingExposure = 0.7;
        scene.environment = THREE.sRGBEncoding;
        ///////////////////////////////////////////////////////////////


        /* 4. Add 3D Object(s) */

        /* 
          Load GLB Model(s) && <skyTexture> 
        */

        const imageLoader = new THREE.TextureLoader();
        
        // eslint-disable-next-line
        const [gltf, skyTexture] =  await Promise.all([
          loadAsyncGLTF({ 
            scene, 
            mixers, 
            modelSrc: model.modelSrc,
            animationName: model.animationName,
            position: model.position,
            scale: model.scale,
            rotation: model.rotation,
          }),
          imageLoader.loadAsync(skyBg[model.modelBg]),
        ]);
		
        const skyGeometry = new THREE.SphereGeometry(300, 50, 32);
        const skyMaterial = new THREE.MeshBasicMaterial({ 
          map: skyTexture,
          side: THREE.BackSide
        });
    
        const sky = new THREE.Mesh(skyGeometry, skyMaterial);
        sky.position.set(0, 50, 0);
        scene.add(sky);


        /* Add AmbientLight */
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        /* Add PointLight */
        const pointLightColor = 0xffffff;
        const pointLightIntensity = 1;
        const pointLight = new THREE.PointLight(pointLightColor, pointLightIntensity);
        pointLight.castShadow = true;
        pointLight.shadow.mapSize.width = 2048;
        pointLight.shadow.mapSize.height = 2048;
        pointLight.shadow.radius = 5;
        pointLight.position.set(40, 120, 40);
        scene.add(pointLight);


        ///////////////////////////////////////////////////////////////
        /* Load GLB Model(s) */

        await Promise.all([
          loadAsyncGLTF({ 
            scene, 
            mixers, 
            modelSrc: model.modelSrc,
            animationName: model.animationName,
            position: model.position,
            scale: model.scale,
            rotation: model.rotation,
          }),
        ]);
        ///////////////////////////////////////////////////////////////


        /* 5. Setup orbital controls */
        const controls = new OrbitControls(camera, canvas);
        controls.enableKeys = false;
        controls.enablePan = false;
        controls.enableZoom = true;
        controls.enableDamping = true;
        controls.enableRotate = true;
        controls.autoRotate = true;
        controls.minPolarAngle = 0 * (Math.PI / 180);
        controls.maxPolarAngle = 70 * (Math.PI / 180); 
        controls.minDistance = 50;
        controls.maxDistance = 200;


        /* 6. run animate() -> <requestAnimationFrame> */
        const clock = new THREE.Clock();

        const animate = () => {
          controls.update(); // orbitControls.autoRotate is enabled so orbitControls.update must be called inside animation loop.
          requestAnimationFrame(animate);
          
          const delta = clock.getDelta();

          mixers?.forEach((mixer) => {
            mixer.update(delta);
          })

          camera.lookAt(scene.position);
          camera.updateProjectionMatrix();

          renderer.render(scene, camera);

        };


        animate();

        /* Adjust resized Screen width & height */
        const resize = new Resize(camera, renderer);
        resize.start();

      };

      return navigator.onLine
        ? renderScene() 
        : console.error("Please make sure that you are connected to the Internet!");

    };

    /* FINALLY!! - run createScene() */
    createScene();

  }, [model]);

  return (
    <div 
      ref={containerRef}
      className="w-full h-full"
    >
      <canvas
        ref={canvasRef}
        id="canvas"
        width="100%"
        height="100%"
        style={{ ...canvasStyle }}
      />
    </div>
  ); 
};

export default Floor2DetailScene;
