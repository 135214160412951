import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import bg from "./img/auth-bg.jpg";
import StorageContext from "../../utils/contexts/StorageContext";
import ModalContext from "../../utils/contexts/ModalContext";
import TopNavBar from "../../components/TopNavBar"; 
import { RequestResetPasswordSuccessModal, RequestResetPasswordFailModal } from "../../components/Modal";
import { postWebRequestResetPasswordUserAPI } from "../../utils/visitorLogAxiosAPI";
import { encryptWithAES } from "../../utils/crypto";

const RequestResetPassword = () => {
  const storageContext = useContext(StorageContext); 
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [isRequestResetPasswordModalRequired, setIsRequestResetPasswordModalRequired] = useState(false);

  const { mutate, isLoading } = useMutation(postWebRequestResetPasswordUserAPI, {
    onSuccess: (data) => {
        // console.log("useMutation Success response data: ", data?.data);
        if (data?.data?.ok === true) { 
          setIsResponseSuccess(true); 
          setIsRequestResetPasswordModalRequired(true);
        }
    },
    onError: (error, variables) => {
        // console.log("useMutation Error response data: ", error?.response?.data);
        if (error?.response?.data?.ok === false) { 
          setIsResponseSuccess(false); 
          setIsRequestResetPasswordModalRequired(true);
        }
    },
  });

    const { register, handleSubmit, formState: { errors } } = useForm({ // getValues, watch, , setError, reset, setError, setValue , clearErrors
        mode: "onBlur", // mode: "all", "onBlur", "onChange", "onTouched"
        defaultValues: {
          email: "",
          password: "",
        },
    });

    const onValid = (submittedFormData) => {
      const { email } = submittedFormData;

      if (isLoading || !email) return;
      setSubmittedEmail(email);

      const updateData = { email };

      const encryptedUpdateData = encryptWithAES(updateData);
      if (!encryptedUpdateData) return;

      mutate({ data: encryptedUpdateData });

    };
    
    const onInvalid = (errors) => {
        console.log("onInvalid errors: ", errors);
        console.log("This is onInvalid!");
    };

    return (
      <div>
        <TopNavBar />
        <div className="flex w-screen h-screen justify-center items-center">
            <img src={bg} alt="auth-bg-03" className="fixed w-full h-full object-cover" />
            <div className="w-screen inset-0 max-w-sm lg:w-96 z-10 bg-gray-300 bg-opacity-70 p-10 rounded-xl">
                
                {/* <h2 className="text-center text-4xl font-thin tracking-tight text-gray-900">새로운 비밀번호 설정</h2> */}
                
                <form 
                  onSubmit={handleSubmit(onValid, onInvalid)}
                  className="space-y-6"
                  spellCheck="false"
                >

                {/* (1). EMAIL ADDRESS */}
                <div>
                  <label 
                      htmlFor="email" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    {storageContext.localLanguage !== "english" ? "이메일 주소": "Email Address"}
                  </label>
                    <input
                        id="email"
                        name="email"
                        label="email"
                        type="email"
                        autoComplete="email" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                        placeholder="ex. ******@gmail.com"

                        {...register("email", {
                            required: storageContext.localLanguage !== "english" ? "이메일 주소는 필수입니다.": "Email is required.", // "이메일 주소는 필수입니다.",
                            minLength: {
                                message: storageContext.localLanguage !== "english" ? "이메일 주소는 7자리 이상이여야 합니다.": "Email must be at least 7 characters.", // "이메일 주소는 7자리 이상이여야 합니다.",
                                value: 7,
                            },
                            pattern: {
                              value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 
                              message: storageContext.localLanguage !== "english" ? "잘못된 이메일 형식입니다." : "Wrong email format", // "잘못된 이메일 형식입니다."
                            },
                            validate: {
                              notContainsAt: (value) => value.includes("@") || (storageContext.localLanguage !== "english" ? "이메일 주소에는 '@'이 포함되어야 합니다." : "Sorry, a valid email address should contain '@'."), // if return false -> validation error!
                              lowercase: (value) => value === value.toLowerCase() || (storageContext.localLanguage !== "english" ? "이메일 주소는 소문자여야 합니다." : "Sorry, an email address should be lowercase chars."), // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || (storageContext.localLanguage !== "english" ? "이메일 주소에는 공백이 없어야 합니다." : "Sorry, an email address should not contain any whitespace."),
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors?.email?.message}</p>
                </div>

                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-lg font-light text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                >
                  {storageContext.localLanguage !== "english" ? "새로운 비밀번호 설정" : "Set a new password"}
                </button>
              </form>
            </div>

          <ModalContext.Provider value={{ isRequestResetPasswordModalRequired, setIsRequestResetPasswordModalRequired }}>
            {(isResponseSuccess && submittedEmail)
              ? <RequestResetPasswordSuccessModal message={storageContext.localLanguage !== "english" ? "이메일로 발송된 인증번호를 확인해 주세요!" : "Please check the authentication number sent by email!"} moveToPage={`/reset-password?email=${submittedEmail}`} /> 
              : (!isResponseSuccess)
              ? <RequestResetPasswordFailModal message={storageContext.localLanguage !== "english" ? "다시 시도해 주세요!" : "Please try again!"} />

              : null} 

            {/* {isResponseSuccess && submittedEmail && <RequestResetPasswordSuccessModal message="이메일로 발송된 인증번호를 확인해 주세요!" moveToPage={`/reset-password?email=${submittedEmail}`} /> }
            {!isResponseSuccess && <RequestResetPasswordFailModal message="다시 시도해 주세요!" /> } */}
          </ModalContext.Provider>
        </div>
      </div>
    )
};

export default RequestResetPassword;
  