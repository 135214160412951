const VMFloor3ObjectsList = {
    "1": {
        title: "#1: SUN",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-sun.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Sun.glb",
            animationName: "Take 001",
            position: [0, 25, 0], 
            scale: [2, 2, 2], 
            rotation: [0, 160, 0],
        },
    },
    "2": {
        title: "#2: MERCURY",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-mercury.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Mercury.glb",
            animationName: "Take 001",
            position: [0, 25, 0], 
            scale: [2, 2, 2], 
            rotation: [0, 100, 0],
        },
    },
    "3": {
        title: "#3: VENUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-venus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Venus.glb",
            animationName: "Take 001",
            position: [0, 25, 0], 
            scale: [0.3, 0.3, 0.3], 
            rotation: [0, 160, 0],
        },
    },
    "4": {
        title: "#4: EARTH",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-earth.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Earth.glb",
            animationName: "Take 001",
            position: [0, 25, 0], 
            scale:[0.7, 0.7, 0.7],
            rotation: [0, 10, 0],
        },
    },
    "5": {
        title: "#5: MARS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-mars.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Mars.glb",
            animationName: "Rotation",
            position: [0, 25, 0], 
            scale: [7, 7, 7], 
            rotation: [0, 10, 0],
        },
    },
    "6": {
        title: "#6: JUPITER",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-jupiter.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Jupiter.glb",
            animationName: "ArmatureAction",
            position: [0, 25, 0], 
            scale: [12, 12, 12], 
            rotation: [0, 160, 0],
        },
    },
    "7": {
        title: "#7: SATURN",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-saturn.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Saturn.glb",
            animationName: "Take 001",
            position: [0, 25, 0], 
            scale: [12, 12, 12], 
            rotation: [0, 10, 0.4],
        },
    },
    "8": {
        title: "#8: URANUS",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-uranus.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Uranus.glb",
            animationName: "Uranus|Take 001|BaseLayer",
            position: [0, 25, 0], 
            scale: [0.03, 0.03, 0.03], 
            rotation: [0, 10, 0],
        },
    },
    "9": {
        title: "#9: NEPTUNE",
        docentVideoSrc: process.env.PUBLIC_URL + "/videos/ai-docent/floor3/floor3-ai-docent-neptune.mp4",
        model: {
            modelSrc: process.env.PUBLIC_URL + "/models/glb/floor3/Neptune.glb",
            animationName: "08_Neptune|08_NeptuneAction.001",
            position: [0, -20, 0], 
            scale: [0.17, 0.17, 0.17], 
            rotation: [0, 10, 0],
        },
    },
};

export default VMFloor3ObjectsList;