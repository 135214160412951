class Resize {

    constructor(camera, renderer) {
        this.camera = camera;
        this.renderer = renderer;
    };

    start() {
        window.addEventListener("resize", () => this.resize());
    };

    stop() {
        window.removeEventListener("resize", () => this.resize());
    };

    resize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    };
};

export default Resize;