import React, { useState, useContext } from "react";
import { clsx } from "../../utils/mergeClassNames";
import { isMobile } from "react-device-detect";
import StorageContext from "../../utils/contexts/StorageContext";

const MobileMainPopup = () => {

    const [open, setOpen] = useState(true); 

    const storageContext = useContext(StorageContext); 

    if (!isMobile) return;
    
    return (
        <div className={clsx(
            "fixed flex items-center justify-center inset-0 bg-gray-800 bg-opacity-90 overflow-y-auto h-full w-full z-50",
            open ? "" : "hidden"
        )}>
        
        <div className="absolute w-screen h-screen flex items-center justify-center z-100 bg-gray-900 bg-opacity-40">
          <div className="relative w-full text-white">
            
            <div className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center font-bold text-green-500">
                {storageContext.localLanguage !== "english"
                    ? "서대문자연사박물관"
                    : "SEODAEMUN" 
                }
            </div>
            
            <div className="flex justify-center items-center p-7">
                <div 
                    // bg-gray-700 bg-opacity-40
                    className="whitespace-pre-line font-extralight text-base sm:text-xl md:text-3xl rounded-lg p-5 text-gray-200 hover:bg-green-700 hover:bg-opacity-100"
                >
                    {storageContext.localLanguage !== "english"
                    ? "PC로 접속하시면 프리미엄 메타버스 박물관을 체험할 수 있습니다.\n\n웹 메타버스 박물관은 간단한 관람을 위한 것이며, 메인 홈페이지 관림하시면 됩니다."
                    : "You can enter the Premium Metaverse Museum with PC.\n\nThe Web Metaverse Museum is for simple viewing." // and you can scroll down to the bottom of the homepage to enter the Web Metaverse Museum.
                    }
                </div>
            </div>

            <div className="flex items-center justify-center">
                <button
                    onClick={() => setOpen(false)}
                    className="px-5 py-2 bg-green-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                >
                    {storageContext.localLanguage !== "english" ? "확인" : "Confirm"}
                </button>
            </div>

          </div>
        </div>
      </div>
    )
};

export default MobileMainPopup;