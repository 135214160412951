import { useNavigate } from "react-router-dom";
// import { useContext } from 'react'; // useState, useEffect 
// import { useNavigate } from 'react-router-dom';
import { clsx } from "../../utils/mergeClassNames";
import { isDesktop } from "react-device-detect";
// import VideoLoadContext from '../../libs/client/VideoLoadContext';
// import bgUnityTeaserPoster from "../../pages/Main/img/bg-video-unity-teaser-poster.png";
// import bgPaleozoicPoster from "../../pages/Main/img/bg-video-paleozoic-poster.png";
// import bgMesozoicPoster from "../../pages/Main/img/bg-video-mesozoic-poster.png";
// import bgCennozoicPoster from "../../pages/Main/img/bg-video-cenozoic-poster.png";

const Slider = ({ id, title, subtitle, href, videoSrc, language }) => {
    const navigate = useNavigate();
    
    // const moveToVrSpace = (href) => { 
    //   // navigate(-1): redirect to "previous page" === history.goBack()
    //   // navigate(`/metaverse-space/${id}`): redirect to "detail page"
    //   navigate(href); 
    // };

    // const videoLoadContext = useContext(VideoLoadContext); 
    // const navigate = useNavigate();
    // *Next Page에서 props 전달받기
    // const { id } = useParams();
    // const locationName = location?.state?.name; // (props) received from the previous page
    // const locationJob = location?.state?.job; // (props) received from the previous page

    return (
      <div className="relative w-screen h-screen bg-gray-800 bg-opacity-70">
        {/* Background Video BEGINS */}
        <video
          id={`videoId${id}`}
          // poster={
          //   id === 1 ? bgUnityTeaserPoster
          //   // :id === 3 ? bgPaleozoicPoster
          //   // :id === 4 ? bgMesozoicPoster
          //   // :id === 5 ? bgCennozoicPoster
          //   : ""
          // }
          src={videoSrc}
          autoPlay 
          loop muted playsInline
          // onLoadedData={() => videoLoadContext.setVideoLoaded({ videoId: id })}
          className="absolute object-cover w-screen h-screen top-0 left-0 z-0 opacity-90"
        >
          {/* <source src={videoSrc} type="video/webm"/> */}
          {/* <source src={videoSrc} type="video/mp4"/> */}
        </video>
        {/* Background Video ENDS */}
        

        {/* FIXED COMPONENT BEGINS */}
        <div className={clsx(
          "absolute w-screen h-screen flex items-center justify-center z-100",
          id !== 0 ? "bg-gray-900 bg-opacity-40" : ""
        )}
        >
          <div className={clsx(
            "relative w-full text-white",

            ""
            // id === 2 ? "-top-10" 
            // : id !== 1 ? "-top-10"
            // : ""
          )}>
            
            <div className={clsx(
              "text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center font-bold",
              id === 1 ? "text-green-500" // "text-gray-100" // text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-gray-300
              : id === 2 ? "text-gray-100" // text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-gray-300
              : id === 3 ? "text-transparent bg-clip-text bg-gradient-to-r from-blue-800 to-gray-200"
              : id === 4 ? "text-transparent bg-clip-text bg-gradient-to-r from-emerald-600 to-gray-300"
              : id === 5 ? "text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-700"
              : ""
            )}>
              {title}
            </div>
            
            {id !== 1 &&
            <div className="relative flex items-center justify-center -top-4">
              <div className={clsx(
                  language === "English" ? "w-[80%] max-w-[350px] sm:w-[60%] sm:max-w-[480px] md:w-[60%] md:max-w-[500px] lg:max-w-[550px] bg-gradient-to-r opacity-90 rounded-2xl mt-10 md:mt-12 p-5 sm:p-7 text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-light sm:font-extralight" // sm:text-green-500 md:text-red-500 lg:text-black
                  : "w-[65%] max-w-[400px] sm:w-[70%] sm:max-w-[380px] md:w-[60%] md:max-w-[420px] lg:max-w-[500px] bg-gradient-to-r opacity-90 rounded-2xl mt-10 md:mt-12 p-5 sm:p-7 text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-light sm:font-extralight", // sm:text-green-500 md:text-red-500 lg:text-black

                  id === 1 ? ""
                  : id === 2 ? "from-transparent to-yellow-500"
                  : id === 3 ? "from-transparent to-blue-700"
                  : id === 4 ? "from-transparent to-emerald-700"
                  : id === 5 ? "from-transparent to-gray-700"
                  : ""
              )}>
                {subtitle}
              </div>
            </div>
            }
            
            {id !== 1 &&
            <div className="flex justify-center items-center absolute w-full text-center -bottom-20 sm:-bottom-24 md:-bottom-24 text-base animate-bounce">
              <button 
                className={clsx("w-40 border-2 rounded-full px-1 py-2",
                id === 3 ? "border-blue-500 text-blue-500" 
                : id === 4 ? "border-green-500 text-green-500"
                : id === 5 ? "border-gray-400 text-gray-300"
                : ""
                )}
                // href={href}
                // onClick={moveToVrSpace(href)}
                // onClick={() => navigate(href)}

                onClick={() => { 
                  if (!href.includes(".html")) { 
                    navigate(href) 
                  }
                  else {
                    window.location.href = href;
                  }
                }}
              >
                {language === "English" ? "ENTER" : "입장하기"}
              </button>
            </div>}

            {(id === 1) && isDesktop &&
              <div className="mt-[4.5rem] flex justify-center items-center animate-bounce">
              <button 
                className="font-extralight text-lg sm:text-xl md:text-3xl rounded-lg p-5 bg-green-600 text-gray-200 bg-opacity-80 hover:bg-green-700 hover:bg-opacity-100"
                onClick={() => navigate("/download")}
              >
                {language === "English" ? "Explore Metaverse Museum" : "메타버스 박물관 입장"}
              </button>
              </div>}

            {(id === 1) && !isDesktop &&
              <div className="mt-5 flex justify-center items-center p-7">
              <div 
                className="whitespace-pre-line font-extralight text-base sm:text-xl md:text-3xl rounded-lg p-5 bg-gray-700 bg-opacity-40 text-gray-200 hover:bg-green-700 hover:bg-opacity-100"
              >
                {language === "English" 
                  ? "You can enter the Premium Metaverse Museum with PC.\n\nThe Web Metaverse Museum is for simple viewing, and you can scroll down to the bottom of the homepage to enter the Web Metaverse Museum." 
                  : "PC로 접속하시면 프리미엄 메타버스 박물관을 체험할 수 있습니다.\n\n웹 메타버스 박물관은 간단한 관람을 위한 것이며, 홈페이지 아래로 스크롤 다운해서 관림하시면 됩니다."
                }
              </div>
              </div>}

          </div>
        </div>
        {/* FIXED COMPONENT ENDS */}
      </div>
      )
  };

  export default Slider;